import React from "react";
import Image from "next/image";
import { Card, Button } from "day8-ui";

const SignUp5050 = () => {
    return (
        <div className="dui-container dui-mx-auto">
            <Card className="!dui-p-0 dui-min-h-0">
                <div className="dui-flex dui-flex-col md:dui-flex-row-reverse dui-w-full">
                    <div className="dui-hidden lg:dui-block lg:dui-w-1/2 dui-relative">
                        <Image
                            alt="image"
                            className="dui-w-full dui-h-[350px] dui-min-h-full dui-object-cover"
                            src="https://assets.theyachtweek.com/wp-content/uploads/2024/09/TOFU-LP-SKIP-THE-QUEUE-1.jpg"
                            height={200}
                            width={400}
                        />
                    </div>
                    <div className="dui-w-full lg:dui-w-1/2 lg:dui-px-16 lg:dui-py-10 dui-p-4 dui-bg-sunny-yellow-100 dui-flex dui-flex-col dui-justify-center dui-text-primary-950">
                        <h3 className="dui-text-5xl dui-font-heading !dui-font-normal dui-mb-6 dui-text-primary-950 dui-uppercase">Get the inside line.</h3>
                        <p className="dui-mb-8">Get first-hand accounts from other Yacht Weekers. Be the first to see upcoming discounts and deals. And find our destination must-sees: from historic castle hikes to island-hopping cheat sheets.</p>
                        <form className='dui-flex dui-flex-col dui-gap-4 xl:dui-gap-4 xl:dui-flex-row dui-w-full'>
                            <input placeholder='Enter your email' className="dui-mb-0 dui-w-full xl:dui-w-auto dui-border-2 dui-border-primary-950 dui-border-solid dui-h-14 dui-px-6 dui-py-4 dui-text-lg" />
                            <Button
                                type="submit"
                                buttonSize="SMALL"
                                buttonType="SECONDARY"
                                className="dui-grow dui-self-start dui-mb-0 dui-font-subheading dui-font-bold dui-uppercase dui-w-full dui-h-full"
                            >Subscribe</Button>
                        </form>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default SignUp5050;